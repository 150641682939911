import React from "react";

// TODO: add SEO for about page, terms, privacy policy

const About = () => (
  <div className="max-w-screen-xl mx-auto">
    <div className="mt-16 max-w-xl mx-auto text-center">
      <h1 className="text-gray-900 tracking-tight font-bold text-4xl p-4 ">
        We're building the best way to learn online — together.
      </h1>
      <h2 className="text-gray-700 tracking-tight text-xl p-4">
        Enlight is the educational platform for cohort-based courses focused on
        development. We provide the best-in-class education paired with a
        supportive community and accountability.
      </h2>
      —
    </div>

    <div className="mt-4 max-w-xl mx-auto p-2">
      <h2 className="text-gray-700 tracking-tight text-xl text-center">
        <b>Our mission is to increase access to education for all.</b> Enlight
        offers project-based learning in the form of online tutorials and
        cohort-based courses. We work with great educators to run scalable,
        effective, and meaningful learning experiences centered around
        development.
      </h2>
    </div>

    <div className="mt-4 max-w-screen-md mx-auto p-2">
      <h1 className="text-gray-900 tracking-tight text-3xl text-center font-bold">
        Team
      </h1>
      <div className="md:flex w-full mt-4">
        <div className="border flex-1 m-2 p-2 text-center">
          <div className="flex justify-center">
            <img
              className="h-16 rounded-full m-2"
              src="/img/cohort/samay_headshot.jpg"
            />
          </div>
          <p className="text-xl">Samay Shamdasani</p>
          <p className="text-gray-600">Cofounder, CEO</p>
        </div>{" "}
        <div className="border flex-1 m-2 p-2 text-center">
          <div className="flex justify-center">
            <img
              className="h-16 rounded-full m-2"
              src="/img/cohort/maxim_headshot.jpg"
            />
          </div>
          <p className="text-xl">Maxim Geller</p>
          <p className="text-gray-600">Cofounder, COO</p>
        </div>
      </div>
      <div className="md:flex w-full text-gray-800">
        <div className="border flex-1 m-2 p-2 text-center">
          <div className="flex justify-center">
            <img
              className="h-16 rounded-full m-2"
              src="/img/courses/figma/zoe.jpg"
            />
          </div>
          <p className="text-xl">Zoe Robinson</p>
          <p className="text-gray-600">Figma Cohort, Lead</p>
        </div>{" "}
        <div className="border flex-1 m-2 p-2 text-center">
          <div className="flex justify-center">
            <img className="h-16 rounded-full m-2" src="/img/cohort/atul.jpg" />
          </div>
          <p className="text-xl">Atul Gera</p>
          <p className="text-gray-600">Engineering Fellow</p>
        </div>
        <div className="border flex-1 m-2 p-2 text-center">
          <div className="flex justify-center">
            <img
              className="h-16 rounded-full m-2"
              src="/img/cohort/patrick.jpg"
            />
          </div>
          <p className="text-xl">Patrick Morgan</p>
          <p className="text-gray-600">Engineering Fellow</p>
        </div>
      </div>
    </div>

    {/*   <p>
    STATS
    1 mil pageviews
    40+ projects
    X users 
    Cohort learners 
  </p> */}

    {/* TIMELINE
    <section class="text-gray-600 body-font max-w-screen-md mx-auto">
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
          <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">
            1
          </div>
          <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
            <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12"
                viewBox="0 0 24 24"
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
              </svg>
            </div>
            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
              <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">
                Enlight Blog (2016)
              </h2>
              <p class="leading-relaxed">
                VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk
                bespoke try-hard cliche palo santo offal.
              </p>
            </div>
          </div>
        </div>
        <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
          <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">
            2
          </div>
          <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
            <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12"
                viewBox="0 0 24 24"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div>
            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
              <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">
                Enlight Platform (2018)
              </h2>
              <p class="leading-relaxed">
                VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk
                bespoke try-hard cliche palo santo offal.
              </p>
            </div>
          </div>
        </div>
        <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
          <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">
            3
          </div>
          <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
            <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="5" r="3"></circle>
                <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
              </svg>
            </div>
            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
              <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">
                Growth (2020)
              </h2>
              <p class="leading-relaxed">
                VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk
                bespoke try-hard cliche palo santo offal.
              </p>
            </div>
          </div>
        </div>
        <div class="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
          <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-blue-500 text-white relative z-10 title-font font-medium text-sm">
            4
          </div>
          <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
            <div class="flex-shrink-0 w-24 h-24 bg-blue-100 text-blue-500 rounded-full inline-flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
              <h2 class="font-medium title-font text-gray-900 mb-1 text-xl">
                Cohort-based courses (2021)
              </h2>
              <p class="leading-relaxed">
                VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk
                bespoke try-hard cliche palo santo offal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </div>
);

export default About;
